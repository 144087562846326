import callApi from "./baseService";

const basePath = "/media";

export const uploadMedia = (media) => {
  const formData = new FormData();
  for (const prop in media) {
    formData.append(prop, media[prop]);
  }

  return callApi({
    method: "POST",
    endpoint: basePath,
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getMedia = (id, token) => {
  return callApi({ endpoint: `${basePath}/${id}/token/${token}` });
};

export const getMediaSourceUrl = (viewToken) => {
  return callApi({ endpoint: `${basePath}/view/${viewToken}` });
};

export const setMediaPassword = (id, { mediaToken, password, hint = "" }) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/${id}/password`,
    body: { mediaToken, password, hint },
  });
};

export const decryptMedia = (id, password) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/${id}/decrypt`,
    body: { password },
  });
};
