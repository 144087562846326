<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn outlined text @click="goToMediaList">
          <MyIcon name="mdi:arrow-left" />
          Media list
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div v-if="media.forPublic" style="height: auto">
          <FileViewer :file-name="media.name" :source="media.sourceUrl" />
        </div>
        <div v-else>
          <v-row justify="center">
            <v-col cols="12" lg="4" md="6">
              <FormPassword />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormPassword from "../components/FormPassword";
import FileViewer from "../components/Viewers/FileViewer";
import { R_PROFILE_MEDIA_PAGE } from "@/constants/router";
import MyIcon from "../components/MyIcon";

export default {
  name: "ProfileViewMedia",
  components: { MyIcon, FileViewer, FormPassword },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      R_PROFILE_MEDIA_PAGE,
    };
  },
  computed: mapState({
    media: (state) => state.media,
  }),
  methods: {
    goToMediaList() {
      this.$router.push({ name: R_PROFILE_MEDIA_PAGE });
    },
  },
};
</script>
