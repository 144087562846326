<template>
  <v-app-bar app color="black" dark elevation="0">
    <v-toolbar-title>
      <router-link
        class="font-weight-bold white--text"
        style="text-decoration: none"
        title="iShareTo"
        to="/"
      >iShareTo
      </router-link>
    </v-toolbar-title>
    <v-spacer />

    <div v-if="$vuetify.breakpoint.smAndUp" class="d-inline-flex">
      <div v-if="user.id">
        <div class="d-inline-flex align-center">
          <div class="mx-4">
            <ButtonPlan v-if="!user.subscribed" />
          </div>
          <div class="mx-4">
            <ButtonProfile />
          </div>
          <div class="mx-2">
            <ButtonLogout />
          </div>
        </div>
      </div>
      <div v-else class="d-inline-flex">
        <v-btn
          v-for="route in mainRoutes"
          :key="route.name"
          :to="{ path: route.path }"
          text
        >
          {{ route.meta.title }}
        </v-btn>
      </div>
    </div>
    <v-btn v-else icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on" aria-label="Menu">
            <MyIcon name="mdi:dots-vertical" />
          </v-btn>
        </template>
        <v-list v-if="user.id">
          <v-list-item v-if="!user.subscribed">
            <ButtonPlan />
          </v-list-item>
          <v-list-item v-for="route in profileRoutes" :key="route.name">
            <v-btn :to="{ path: route.path }" block text>
              {{ route.meta.title }}
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn block text @click="logout">Logout</v-btn>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item v-for="route in mainRoutes" :key="route.name">
            <v-btn :to="{ path: route.path }" block text
            >{{ route.meta.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

import { logoutMixin } from "../mixin/logoutMixin";
import ButtonProfile from "./ButtonProfile";
import ButtonLogout from "./ButtonLogout";
import MyIcon from "./MyIcon";
import ButtonPlan from "./ButtonPlan";

export default {
  name: "TopBar",
  mixins: [logoutMixin],
  components: { ButtonPlan, MyIcon, ButtonLogout, ButtonProfile },
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      mainRoutes: [],
      profileRoutes: [],
    };
  },
  created() {
    this.mainRoutes = this.$router.options.routes.filter(
      (r) => r.meta && r.meta.mainRoute
    );
    this.profileRoutes = this.$router.options.routes.find(
      (r) => r.meta && r.meta.profileRoute
    ).children;
  }
};
</script>
