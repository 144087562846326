<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">Upload</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        <span>Upload</span>
        <div class="flex-grow-1"></div>
        <v-btn icon @click="closeDialog">
          <MyIcon name="mdi:close" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <InputFile @change="fileChanged" />
            <div class="my-2" />
            <SelectExpiryTime @change="expiryTimeChanged" />
            <div>
              <v-checkbox color="black" v-model="showSetPassword" label="Set password" />
            </div>
            <div v-if="showSetPassword">
              <InputPassword @input="passwordChanged" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="mt-auto"></div>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col>
            <ButtonUploads :media-upload="mediaUpload"></ButtonUploads>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import SelectExpiryTime from "./SelectExpiryTime";
import ButtonUploads from "./ButtonUploads";
import InputFile from "./InputFile";
import InputPassword from "./InputPassword";
import { MediaUpload } from "../types/mediaUpload";
import MyIcon from "./MyIcon";

export default {
  name: "ButtonUpload",
  components: { MyIcon, SelectExpiryTime, ButtonUploads, InputFile, InputPassword },
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      dialog: false,
      error: false,
      mediaUpload: new MediaUpload(null, 3),
      showSetPassword: false
    };
  },
  methods: {
    fileChanged(file) {
      this.mediaUpload.media = file;
    },
    expiryTimeChanged(expiryTime) {
      this.mediaUpload.expiryTime = expiryTime;
    },
    passwordChanged({ password, hint }) {
      this.mediaUpload.password = password;
      this.mediaUpload.hint = hint;
    },
    closeDialog() {
      this.mediaUpload = new MediaUpload(null, 3);
      this.error = false;
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
