import { mapState } from "vuex";

export const profileMixin = {
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    async pullInfo() {
      await this.$store.dispatch("user/getUserInfo");
    },
  },
};
