<template>
  <v-app v-if="show">
    <AppLoading />
    <TopBar />
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="black" dark tile>
        <v-col cols="12">
          <div class="d-flex">
            <div>
              <router-link to="/faq" class="text-decoration-none white--text" title="FAQ">FAQ</router-link>
            </div>
            <div class="mx-2"></div>
            <div>
              <router-link to="/privacy-policy" class="text-decoration-none white--text" title="FAQ">Privacy&Policy
              </router-link>
            </div>
            <div class="mx-2"></div>
            <div>
              <router-link to="/term-of-use" class="text-decoration-none white--text" title="FAQ">Term Of Use
              </router-link>
            </div>
          </div>
          <div>©{{ new Date().getFullYear() }} iShareTo</div>
        </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import TopBar from "./components/TopBar.vue";
import AppLoading from "./components/AppLoading";
import { mapState } from "vuex";
import { version } from "../package.json";

export default Vue.extend({
  components: { TopBar, AppLoading },
  computed: mapState({
    app: (state) => state.app,
  }),
  data() {
    return {
      show: false,
    };
  },
  created() {
    // eslint-disable-next-line no-console
    console.log(`v${version}`);
    this.checkLogin();
  },
  methods: {
    async checkLogin() {
      try {
        await this.$store.dispatch("user/validateUser");
        /* eslint-disable-next-line */
      } catch (ignoreError) {
      }
      this.show = true;
    },
  },
});
</script>
