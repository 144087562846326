<template>
  <v-tooltip  :open-on-hover="true" bottom class="ml-2">
    <template #activator="{ on }">
      <v-btn outlined
             v-on="on"
             @click="copy"
             @blur="show = false"
      >
        <MyIcon name="mdi:link-variant" />
      </v-btn>
    </template>
    <span>{{ info }}</span>
  </v-tooltip>
</template>

<script>
import MyIcon from "./MyIcon";
export default {
  name: "ButtonCopy",
  components: { MyIcon },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      info: "Copy URL",
    };
  },
  methods: {
    copy() {
      this.show = true;
      this.$copyText(this.content).then(
        () => {
          this.info = "URL copied";
        },
        () => {
          this.info = "Please copy the URL manually";
        }
      );
      setTimeout(() => {
        this.show = false;
        this.info = 'Copy URL'
      }, 1500);
    },
  },
};
</script>
