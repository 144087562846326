<template>
  <div v-if="app.loading" class="page-loading">
    <div class="page-loading-content">
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="amber"
          indeterminate
        ></v-progress-circular>
      </div>
      <div>
        {{ app.loadingText }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppLoading",
  computed: mapState({
    app: (state) => state.app,
  }),
};
</script>

<style scoped>
.page-loading {
  display: flex;
  z-index: 6;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}

.page-loading-content {
  color: white;
}
</style>
