import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores";
import "./assets/styles/index.scss";
import vuetify from "./plugins/vuetify";
import { sync } from "vuex-router-sync";
import VueClipboard from "vue-clipboard2";
import { DateTime } from "luxon";
import { ValidationObserver } from "vee-validate";
import Validator from "./plugins/validator";
import Rollbar from "rollbar";

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_ENV,
  },
});

Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

import { formatTimestamp, humanizeSecond } from "./filters/dateTimeFilter";

Vue.use(VueClipboard);
Vue.config.productionTip = false;
sync(store, router);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Validator", Validator);
Vue.component("ValidationObserver", ValidationObserver);

Vue.mixin({
  methods: {
    formatSeconds: (value, format = "FF") =>
      DateTime.fromSeconds(value).toFormat(format),
  },
});

Vue.filter("formatTimeStamp", formatTimestamp);
Vue.filter("humanizeSecond", humanizeSecond);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
