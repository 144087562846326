import callApi from "./baseService";

const basePath = "/users";

export const registerUser = (payload) => {
  return callApi({ method: "POST", endpoint: basePath, body: payload });
};

export const checkLogin = () => {
  return callApi({ method: "GET", endpoint: `${basePath}/login` });
};

export const login = ({ username, password, token }) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/login`,
    body: { username, password, token },
  });
};

export const logout = () => {
  return callApi({ method: "GET", endpoint: `${basePath}/logout` });
};

export const getUserInfo = () => {
  return callApi({ method: "GET", endpoint: basePath });
};

export const getUserMedias = () => {
  return callApi({ method: "GET", endpoint: `${basePath}/medias` });
};

export const deleteMedia = (id, token) => {
  return callApi({
    method: "DELETE",
    endpoint: `${basePath}/media/${id}/token/${token}`,
  });
};

export const resetPassword = (email) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/resetPassword`,
    body: { email },
  });
};

export const setNewPassword = (payload) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/setPassword`,
    body: payload,
  });
};

export const subscribeUser = (payload) => {
  return callApi({
    method: "POST",
    endpoint: `${basePath}/subscribe`,
    body: payload,
  });
};
