<template>
  <v-file-input
    v-model="file"
    :disabled="loading"
    :loading="loading"
    :rules="rules"
    color="black"
    dense
    :hint="`Limit file size is ${this.maxFileSizeStr} MB!`"
    label="Browse file for upload"
    outlined
    persistent-hint
    prepend-icon=""
    show-size
    @change="fileChanged"
  >
    <template #prepend-inner>
      <MyIcon name="mdi:file-outline" />
    </template>
  </v-file-input>
</template>

<script>
import { mapGetters } from "vuex";
import MyIcon from "./MyIcon";

export default {
  name: "InputFile",
  components: { MyIcon },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      maxFileSize: "app/getMaxFileSize",
      maxFileSizeStr: "app/getMaxFileSizeStr",
    }),
  },
  data() {
    return {
      file: null,
      rules: [
        (value) =>
          !value ||
          value.size < this.maxFileSize ||
          `Max file size is ${this.maxFileSizeStr} MB!`,
      ],
      hint: ``,
    };
  },
  methods: {
    fileChanged() {
      return this.$emit("change", this.file);
    },
  },
};
</script>
