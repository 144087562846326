import { mapState } from "vuex";

export const logoutMixin = {
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
    },
  },
};
