<template>
  <v-data-table
    :headers="headers"
    :items="filteredMedias"
    class="elevation-1"
    disable-sort
    height="inherit"
  >
    <template v-slot:top>
      <div class="d-flex pa-3 justify-end align-center">
        <div>
          <v-select
            v-model="selectedType"
            :items="medias"
            clearable
            dense
            hide-details
            item-text="mediaType"
            item-value="mediaType"
            label="Type"
            outlined
            color="black"
          />
        </div>
        <div class="mx-3" />
        <div>
          <v-checkbox
            color="black"
            v-model="showExpired"
            class="mt-0"
            hide-details
            label="Show expired"
          ></v-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:[`item.createdTime`]="{ item }">
       {{new Date(item.createdTime * 1000).toLocaleString()}}
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <div class="d-flex justify-center flex-grow-1">
        <div v-if="!isExpired(item.expiryTime)">
          <v-btn class="mr-2" outlined @click="handleView(item)">
            <MyIcon name="mdi:eye" />
          </v-btn>
        </div>
        <div v-if="!isExpired(item.expiryTime)">
          <ButtonCopy :content="item.shortUrl" />
        </div>
        <div>
          <ButtonDelete :media="item" />
        </div>
      </div>
    </template>
    <template v-slot:[`item.expiryTime`]="{ item }">
      <td>
        <div v-if="isExpired(item.expiryTime)">
          <div>Already expired on</div>
          {{ item.expiryTime | formatTimeStamp("DD") }}
        </div>
        <div v-else>
          <div>Expire in</div>
          {{ getRemainingSecond(item.expiryTime * 1000) | humanizeSecond }}
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import ButtonCopy from "./ButtonCopy";
import { R_PROFILE_VIEW_MEDIA_PAGE } from "../constants/router";
import ButtonDelete from "./ButtonDelete";
import MyIcon from "./MyIcon";

export default {
  name: "TableMedia",
  components: { MyIcon, ButtonDelete, ButtonCopy },
  props: {
    medias: {
      type: Array,
      required: true,
    },
  },
  computed: mapState({
    media: (state) => state.media,
  }),
  watch: {
    showExpired: function () {
      this.filteredMedias = [...this.filterMedia()];
    },
    selectedType: function () {
      this.filteredMedias = [...this.filterMedia()];
    },
    medias: function() {
      this.filteredMedias = [...this.filterMedia()];
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      unlocked: false,
      sourceUrl: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "mediaType" },
        { text: "View", value: "viewCount", align: "center" },
        { text: "Created Time", value: "createdTime" },
        { text: "Expiry Time", value: "expiryTime" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ],
      filteredMedias: [],
      types: [],
      selectedType: null,
      showExpired: true,
    };
  },
  mounted() {
    this.filteredMedias = [...this.medias];
  },
  methods: {
    filterMedia() {
      let filteredMedias = [...this.medias];
      if (this.selectedType) {
        filteredMedias = filteredMedias.filter((media) => {
          const ext = media.name.split(".").pop();
          return ext === this.selectedType;
        });
      }
      if (!this.showExpired) {
        filteredMedias = filteredMedias.filter((media) => {
          return this.isExpired(media.expiryTime) === false;
        });
      }
      return filteredMedias;
    },
    isExpired(time) {
      return new Date().getTime() / 1000 > time;
    },
    getRemainingSecond(time) {
      return new Date().getTime() - time;
    },
    async handleView(media) {
      await this.$store.dispatch("media/setMedia", media);

      await this.$router.push({
        name: R_PROFILE_VIEW_MEDIA_PAGE,
        params: { id: media.id },
      });

      // this.dialog = true;
    },
    handleUnlock({ sourceUrl }) {
      this.sourceUrl = sourceUrl;
      this.unlocked = true;
    },
  },
};
</script>
