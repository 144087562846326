import {
  checkLogin,
  deleteMedia,
  getUserInfo,
  getUserMedias,
  login,
  logout,
  registerUser,
  resetPassword,
  setNewPassword,
} from "../../services/userService";

const getInitialState = () => ({
  id: "",
  username: "",
  email: "",
  lastLogin: null,
  medias: [],
  plan: "guest",
  cancelUrl: "",
  receiptUrl: "",
  subscribed: false,
  planName: "",
  subscriptionCancelledAt: "",
});
const initialState = getInitialState();

const getters = {};

const actions = {
  /* eslint-disable-next-line */
  async registerUser({ commit }, user) {
    const result = await registerUser(user);
    return result;
  },
  // eslint-disable-next-line no-empty-pattern
  async resetPassword({}, email) {
    await resetPassword(email);
  },
  // eslint-disable-next-line no-empty-pattern
  async setNewPassword({}, { token, password }) {
    await setNewPassword({ token, password });
  },
  async checkLogin({ commit, dispatch }) {
    try {
      dispatch("app/toggleLoading", { loading: true }, { root: true });
      const user = await checkLogin();
      commit("setUser", user);
    } catch (err) {
      console.error(err);
      dispatch("app/toggleLoading", { loading: false }, { root: true });
    }
  },
  async validateUser({ commit }) {
    // we use this only on app loaded for first time
    const user = await checkLogin();
    commit("setUser", user);
  },
  async login({ commit }, { username, password, token }) {
    const user = await login({ username, password, token });
    commit("setUser", user);
  },
  async logout({ commit }) {
    await logout();
    commit("setUser", getInitialState());
  },
  async getUserInfo({ commit }) {
    const user = await getUserInfo();
    commit("setUser", user);
    return user;
  },
  async getUserMedias({ commit }) {
    const medias = await getUserMedias();
    commit("setMedias", medias);
  },
  /* eslint-disable-next-line */
  async deleteMedia({ commit }, { id, token }) {
    const medias = await deleteMedia(id, token);
    commit("setMedias", medias);
  },
};

const mutations = {
  setUser(state, user) {
    for (const prop in user) {
      if (Object.prototype.hasOwnProperty.call(state, prop)) {
        state[prop] = user[prop];
      }
    }
  },
  setMedias(state, medias) {
    state.medias = medias;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
