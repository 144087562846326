<template>
 <v-container>
   <v-row v-if="mediaLoaded">
     <v-col>
       <div v-if="user.medias.length > 0">
         <TableMedia :medias="user.medias" />
       </div>
       <div v-else>
         <v-alert
           block
           border="left"
           class="mt-10"
           color="black"
           colored-border
           outlined
           type="info"
         >
           No file(s)
         </v-alert>
       </div>
     </v-col>
   </v-row>

   <v-row v-else class="mt-10 text-center">
     <v-col>
       <div>
         <span class="font-weight-bold">Loading media list</span>
         <v-progress-linear color="black" indeterminate />
       </div>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import { profileMixin } from "../mixin/profileMixin";
import TableMedia from "../components/TableMedia";

export default {
  name: "ProfileMediaPage",
  mixins: [profileMixin],
  components: { TableMedia },
  data() {
    return {
      mediaLoaded: false,
    };
  },
  created() {
    this.pullMedias();
  },
  methods: {
    async pullMedias() {
      try {
        await this.$store.dispatch("user/getUserMedias");
      } catch (err) {
        console.error(err);
      }
      this.mediaLoaded = true;
    },
  },
};
</script>
