<template>
  <v-btn
    v-if="user.id"
    class="grey lighten-3 black--text"
    icon
    @click="handleProfile"
  >
    <MyIcon name="mdi:clipboard-account" />
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import { R_PROFILE_INFO_PAGE, R_PROFILE_LAYOUT } from "../constants/router";
import MyIcon from "./MyIcon";

export default {
  name: "ButtonProfile",
  components: { MyIcon },
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    handleProfile() {
      if (this.$route.name !== R_PROFILE_INFO_PAGE) {
        this.$router.push({ name: R_PROFILE_LAYOUT });
      }
    },
  },
};
</script>
