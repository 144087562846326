<template>
 <v-container>
   <v-row align="center">
     <v-col class="text-center headline font-weight-medium" cols="12">
       <div class="text-h3">iShareTo</div>
     </v-col>
   </v-row>
 </v-container>
</template>
<script>
export default {
  name: "AboutPage",
};
</script>
