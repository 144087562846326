<template>
  <!-- TODO: protect page -->
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title class="font-weight-bold subtitle-1">
        Hey, {{ user.username }}
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-toolbar-items>
        <v-btn v-for="m of menu" :key="m.name" :to="{ path: m.meta.link }" text>
          {{ m.meta.title }}
        </v-btn>
        <ButtonUpload />
      </v-toolbar-items>
    </v-toolbar>
    <v-divider class="mb-4" />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { R_PROFILE_LAYOUT } from "../constants/router";
import ButtonUpload from "../components/ButtonUpload";

export default {
  name: "ProfileLayout",
  components: {  ButtonUpload },
  computed: mapState({
    user: (state) => state.user,
  }),
  watch: {
    "user.id": function () {
      if (!this.user.id) {
        this.forceToHome();
      }
    },
  },
  data() {
    return {
      menu: [],
    };
  },
  created() {
    this.menu = this.$router.options.routes
      .find((r) => r.name === R_PROFILE_LAYOUT)
      .children.filter((c) => !c.meta.hidden);
  },
  beforeMount() {
    if (!this.user.id) {
      this.forceToHome();
    }
  },
  methods: {
    forceToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
