<template>
  <v-container>
    <v-row>
      <v-col v-if="loaded" cols="12">
        <div v-if="media.id" outlined>
          <div class="subtitle-1">
            <v-row dense>
              <v-col cols="12"
              ><span class="font-weight-bold">View count:</span>
                {{ media.viewCount }}
              </v-col>
              <v-col cols="12">
                <span class="font-weight-bold">URL:</span>
                <span class="mr-3">{{ media.shortUrl }}</span>
                <ButtonCopy :content="media.shortUrl" />
              </v-col>
              <v-col class="mb-2">
                <v-btn
                  color="black"
                  dark
                  depressed
                  title="Upload again"
                  to="/"
                >Upload again
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider />
          <div v-if="media.forPublic" style="height: auto">
            <FileViewer :file-name="media.name" :source="media.sourceUrl" />
          </div>
          <div v-else>
            <v-row justify="center">
              <v-col cols="12" lg="4" md="6">
                <FormPassword />
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else>
          <v-alert class="title font-weight-bold text-center">
            File not found or already expired.
          </v-alert>
          <v-btn :to="{ path: '/' }" block link text x-large
          >Back to main page
          </v-btn>
        </div>
      </v-col>
      <v-row v-else>
        <v-col align="center">
          <v-progress-circular :size="48" color="black" indeterminate />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ButtonCopy from "../components/ButtonCopy";
import FileViewer from "../components/Viewers/FileViewer";
import FormPassword from "../components/FormPassword";

export default {
  name: "ViewPage",
  components: { ButtonCopy, FileViewer, FormPassword },
  computed: mapState({
    media: (state) => state.media,
  }),
  data() {
    return {
      loaded: false,
      source: null,
      viewToken: this.$route.query.viewToken,
    };
  },
  created() {
    (function (s, u, z, p) {
      (s.src = u), s.setAttribute("data-zone", z), p.appendChild(s);
    })(
      document.createElement("script"),
      "https://iclickcdn.com/tag.min.js",
      3787063,
      document.body || document.documentElement
    );
    this.loadFile();
  },
  methods: {
    async loadFile() {
      await this.$store.dispatch("media/getMediaSourceUrl", {
        viewToken: this.viewToken,
      });
      this.loaded = true;
    },
  },
  beforeDestroy() {
    this.loaded = false;
  },
};
</script>
