<template>
  <v-row>
    <v-col>
      <Validator
        v-slot="{ errors }"
        mode="lazy"
        name="Password"
        rules="required|min:4"
      >
        <v-text-field
          ref="password"
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error-messages="errors[0]"
          :type="showPassword ? 'text' : 'password'"
          :validate-on-blur="true"
          color="black"
          dense
          hint="Min. 4 characters"
          label="Password"
          name="password"
          outlined
          required
          @input="handleChange"
          @click:append="showPassword = !showPassword"
        >
          <template #prepend-inner>
            <MyIcon name="mdi:onepassword" />
          </template>
        </v-text-field>
      </Validator>
      <v-textarea
        v-model="hint"
        color="black"
        no-resize
        outlined
        placeholder="Hint"
        rows="2"
        @input="handleChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import MyIcon from "./MyIcon";
export default {
  name: "InputPassword",
  components: { MyIcon },
  data() {
    return {
      password: null,
      hint: "",
      showPassword: false,
    };
  },
  methods: {
    handleChange() {
      this.$emit("input", { password: this.password, hint: this.hint });
    },
  },
};
</script>

<style scoped></style>
