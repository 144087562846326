export const R_HOME_PAGE = "homePage";
export const R_MEDIA_PAGE = "mediaPage";
export const R_VIEW_PAGE = "viewPage";
export const R_PROFILE_LAYOUT = "profileLayout";
export const R_PROFILE_INFO_PAGE = "profileInfoPage";
export const R_PROFILE_MEDIA_PAGE = "profileMediaPage";
export const R_PROFILE_VIEW_MEDIA_PAGE = "profileViewMediaPage";
export const R_ABOUT_PAGE = "aboutPage";
export const R_REGISTER_PAGE = "registerPage";
export const R_LOGIN_PAGE = "loginPage";
export const R_RESET_PASSWORD_PAGE = "resetPasswordPage";
export const R_SET_PASSWORD_PAGE = "setPasswordPage";
export const R_FAQ_PAGE = "faqPage";
export const R_PRIVACY_POLICY = "privacyPolicy";
export const R_TERM_OF_USE = "termOfUse";
