import axios from "axios";

// init axios credential
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.withCredentials = true;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) return response;
  throw response;
}

function getResponse(response) {
  switch (response.status) {
    case 200:
      return { data: response.data, response };

    case 204:
      return { response };

    default:
      return { data: response.data, response };
  }
}

function resolveOrReject({ data, response }) {
  // TODO: figure out why test server doesn't return statusText
  if (!(response.status === 200)) return Promise.reject(data);
  return Promise.resolve(data);
}

// REF: https://github.com/axios/axios#handling-errors
function catchHandler(error) {
  if (error.response) {
    return Promise.reject(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    /* eslint-disable no-console */
    console.error(error);
  }
  return Promise.reject(error);
}

function promiseFetch(request) {
  return request
    .then(checkStatus)
    .then(getResponse)
    .then(resolveOrReject)
    .catch(catchHandler);
}

export default ({
  endpoint,
  method = "GET",
  body = {},
  headers = {},
  options = {},
}) => {
  const requestHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  const url = encodeURI(endpoint);

  switch (method) {
    case "GET":
    case "DELETE":
      return promiseFetch(
        axios({
          url,
          method,
          data: body,
          headers: requestHeader,
          ...options,
        })
      );

    case "POST":
    case "PUT":
      return promiseFetch(
        axios({
          url,
          headers: requestHeader,
          method,
          data:
            requestHeader["Content-Type"] === "multipart/form-data"
              ? body
              : JSON.stringify(body),
          ...options,
        })
      );

    default:
      throw new Error(
        "Unrecognized request method. Please make a correct one."
      );
  }
};
