const initialState = {
  loading: false,
  loadingText: "Please wait...",
  guestMaxFileSize: 10 * 1024 * 1024, // for guest
  userMaxFileSize: 30 * 1024 * 1024, // for registered unpaid
  maxFileSize: 50 * 1024 * 1024, // for paid users
};

const getMaxFileSize = (rootState) => {
  if (rootState.user.plan === "guest") {
    return rootState.app.guestMaxFileSize;
  }
  if (rootState.user.plan === "standard") {
    return rootState.app.userMaxFileSize;
  }
  return rootState.app.maxFileSize;
};

const getters = {
  getMaxFileSize(state, getters, rootState) {
    return getMaxFileSize(rootState);
  },
  getMaxFileSizeStr(state, getters, rootState) {
    let maxFileSize = getMaxFileSize(rootState);
    return Math.round(maxFileSize / 1024 / 1024);
  },
};

const actions = {
  toggleLoading({ commit }, { loading, loadingText }) {
    commit("setLoading", { loading, loadingText });
  },
};

const mutations = {
  setLoading(state, { loading, loadingText = "Please wait..." }) {
    state.loading = loading;
    state.loadingText = loadingText;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
