<template>
  <v-container fluid style="overflow: auto;">

    <v-row>
      <v-col class="text-center">
        <VideoViewer
          v-if="fileTypeGroup === 'video'"
          :source="source"
          :file-name="fileName"
        />

        <picture v-else-if="fileTypeGroup === 'image'">
          <source :src="source" media="(max-height: 100%; max-width: 100%)" />
          <img :src="source" style="max-width: 100%; max-height: 100%" alt="" />
        </picture>

        <audio v-else-if="fileTypeGroup === 'audio'" controls>
          <source :src="source" media="(max-height: 100%; max-width: 100%)" />
        </audio>

        <div v-else>
          <v-btn color="success" @click="downloadFile">
            <MyIcon name="mdi:cloud-download-outline" />
            Download File
          </v-btn>
          <p class="font-weight-bold">{{ fileName }}</p>
          <v-alert outlined type="warning">
            Please do not download file from someone you don't know. It's all on your
            own risk
            .</v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { fileTypeMixin } from "@/mixin/fileTypeMixin";

const VideoViewer = () => import("./VideoViewer");

import MyIcon from "../MyIcon";

export default {
  name: "FileViewer",
  components: { MyIcon, VideoViewer },
  mixins: [fileTypeMixin],
  props: {
    fileName: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileTypeGroup: null,
    };
  },
  mounted() {
    this.fileTypeGroup = this.getFileTypeGroup(this.fileName);
  },
  methods: {
    downloadFile() {
      window.open(this.source, "_blank");
    },
  },
};
</script>
