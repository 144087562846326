var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.user.id)?_c('v-col',{staticClass:"text-center"},[_c('h1',[_vm._v(" You already logged in. Go "),_c('router-link',{attrs:{"to":{ path: '/' }}},[_vm._v("here")]),_vm._v(" to upload file. ")],1)]):_c('v-col',{attrs:{"cols":"10","lg":"5","md":"6"}},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',{ref:"form",on:{"submit":_vm.handleRegister}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-center headline mb-5 font-weight-bold"},[_vm._v(" Register ")]),_c('Validator',{attrs:{"mode":"lazy","name":"Username","rules":"required|max:16|min:6|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"color":"black","dense":"","hint":"Min. 6 chars, max. 16 chars","label":"Username","maxlength":"16","name":"username","outlined":"","persistent-hint":"","prepend-inner-icon":"mdi-account-outline","required":""},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}})]}}])}),_c('Validator',{attrs:{"mode":"lazy","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"color":"black","dense":"","label":"Email","name":"email","outlined":"","prepend-inner-icon":"mdi-at","required":"","type":"email"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})]}}])}),_c('Validator',{attrs:{"mode":"lazy","name":"Password","rules":"required|min:8|password:@ConfirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"error-messages":errors[0],"validate-on-blur":true,"color":"black","dense":"","hint":"Min. 8 characters","label":"Password","name":"password","outlined":"","persistent-hint":"","prepend-inner-icon":"mdi-onepassword","required":"","type":"password"},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})]}}])}),_c('Validator',{attrs:{"mode":"lazy","name":"ConfirmPassword","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"color":"black","dense":"","hint":"Must be equal to password","label":"Confirm Password","name":"confirmPassword","outlined":"","persistent-hint":"","prepend-inner-icon":"mdi-onepassword","required":"","type":"password"},model:{value:(_vm.newUser.confirmPassword),callback:function ($$v) {_vm.$set(_vm.newUser, "confirmPassword", $$v)},expression:"newUser.confirmPassword"}})]}}])}),(_vm.error)?_c('v-alert',{attrs:{"dense":"","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-layout',[_c('v-flex',[_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","color":"black","dark":"","depressed":"","type":"submit"},on:{"click":_vm.handleRegister}},[_vm._v(" Register ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }