<template>
  <v-row justify="space-around">
    <v-col cols="auto" class="my-auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            v-bind="attrs"
            v-on="on"
          >Upgrade!
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            color="black"
            dark
            class="font-weight-bold"
            dense
            elevation="0"
          >Select subscription
          </v-toolbar>
          <v-card-text>
            <v-overlay v-show="loading">
              <v-progress-circular indeterminate />
            </v-overlay>
            <v-row class="my-2">
              <v-col>
                <v-list-item-group
                  v-model="selectedIndex"
                  active-class=""
                >
                  <v-list-item
                    :key="plan.label"
                    v-for="plan of plans">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox color="black" :input-value="active"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ plan.price }} {{ plan.label }}</v-list-item-title>
                        <div class="flex-wrap">
                          {{ plan.description }}
                        </div>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              dark
              depressed
              color="black"
              @click="dialog = false"
            >Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              dark
              depressed
              color="success"
              @click="handleNext"
            >Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { subscribeUser } from "../services/userService";

export default {
  name: "ButtonPlan",
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      dialog: false,
      selectedIndex: null,
      plans: [
        {
          label: "Monthly",
          productId: 25581,
          price: "$4",
          description:
            "Expiry time up to 1 week, 30MB file size per upload, 30 files/month"
        },
        {
          label: "Yearly",
          productId: 25698,
          price: "$35",
          description:
            "Expiry time up to 30 days, 50MB file size per upload, unlimited files"
        }
      ],
      loading: false
    };
  },
  methods: {
    handleNext() {
      const selectedPlan = this.plans[this.selectedIndex];
      // this.dialog = false;
      const passthrough = { userId: this.user.id, email: this.user.email, username: this.user.username };
      window.Paddle.Checkout.open({
        // override: selectedPlan.checkoutLink,
        product: selectedPlan.productId,
        email: this.user.email,
        passthrough,
        displayModeTheme: "dark",
        successCallback: this.showSuccessModal
      });
    },
    async showSuccessModal(data) {
      this.loading = true;
      try {
        await subscribeUser({ checkoutResult: data });
        this.$store.dispatch("user/getUserInfo");
      } catch (err) {
        alert("error");
      }
      this.loading = false;
    }
  }
};
</script>

