<template>
  <v-container>
    <v-progress-linear color="black" indeterminate v-if="!media"></v-progress-linear>
    <v-row justify="center" v-if="media">
      <v-col cols="12" lg="7" md="10">
        <h1>Media Info</h1>
        <v-card outlined>
          <v-card-text>
            <div class="subtitle-1">
              Expiry time: {{ formatSeconds(media.expiryTime) }}
            </div>
            <div class="d-sm-flex d-inline-block align-center">
              <div class="subtitle-1">URL: {{ media.shortUrl }}</div>
              <div class="mx-2"></div>
              <ButtonCopy :content="media.shortUrl" />
            </div>
          </v-card-text>
          <v-card-actions>
            <ButtonSetPassword
              :for-public="media.forPublic"
              :media-id="media.id"
            />
            <div class="ml-auto"></div>
            <v-btn :to="{ path: '/' }" color="black" dark depressed
            >Upload more
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="media.name">
        <v-container>
          <v-row>
            <v-col cols="12">
              <FileViewer :file-name.sync="media.name" :source.sync="media.sourceUrl" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ButtonCopy from "../components/ButtonCopy";
import ButtonSetPassword from "../components/ButtonSetPassword";
import FileViewer from "../components/Viewers/FileViewer";

export default {
  name: "SharedPage",
  components: { FileViewer, ButtonCopy, ButtonSetPassword },
  data() {
    return {
      id: "",
      token: "",
      media: null,
    };
  },
  created() {
    const { id, token } = this.$route.query;

    this.id = id;
    this.token = token;
    this.getMedia();
  },
  methods: {
    async getMedia() {
      try {
        this.media = await this.$store.dispatch("media/getMedia", {
          id: this.id,
          token: this.token,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("media/resetMedia");
  }
};
</script>
