import Vue from "vue";
import Vuex from "vuex";
import media from "./media";
import user from "./user";
import app from "./app";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    media,
    user,
    app,
  },
  strict: debug,
  plugins: debug ? [] : [],
});
