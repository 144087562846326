<template>
  <div>
    <v-btn
      :loading="loading"
      block
      class="black"
      dark
      depressed
      @click="handleUpload"
      aria-label="Upload"
    >
      <MyIcon name="mdi:cloud-upload" />
    </v-btn>
    <v-alert v-if="error" class="mt-5" dense type="error">{{ errorMsg }}</v-alert>
  </div>
</template>

<script>
import { R_MEDIA_PAGE } from "@/constants/router";
import { MediaUpload } from "@/types/mediaUpload";
import { mapGetters } from "vuex";
import { validateRecaptchaToken } from "../services/recaptchaService";
import MyIcon from "./MyIcon";

export default {
  name: "ButtonUploads",
  components: { MyIcon },
  props: {
    mediaUpload: {
      type: MediaUpload,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      maxFileSize: "app/getMaxFileSize",
    }),
  },
  data() {
    return {
      error: false,
      loading: false,
      maxSize: 50 * 1024 * 1024,
      errorMsg: "Uploading error! Please try again or check back later.",
      recaptchaLoaded: false
    };
  },
  methods: {
    async handleUpload() {
      if (!this.mediaUpload.media) {
        return;
      }
      if (this.maxFileSize < this.mediaUpload.media.size) {
        return;
      }

      // reset the errorMsg here
      this.errorMsg = "Uploading error! Please try again or check back later.";

      try {
        this.$emit("uploading", true);

        this.loading = true;
        this.error = false;

        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            "6LeYG5MdAAAAAKCTzZ5RZ0g2SbGDV0QVPeeb47he",
            { action: "upload" }
          );
          try {
            const res = await validateRecaptchaToken(token);
            if (res.success && res.score > 0.5) {
              const { id, token } = await this.$store.dispatch(
                "media/uploadMedia",
                this.mediaUpload
              );
              return await this.$router.push({
                name: R_MEDIA_PAGE,
                query: { id, token },
              });
            }
          } catch (err) {
            if (err.message) {
              this.errorMsg = err.message;
            }
            this.error = true;
          }
          this.$emit("uploading", false);
          this.loading = false;
        });
      } catch (err) {
        this.$emit("uploading", false);
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>
