import callApi from "./baseService";

const basePath = "/recaptcha";

/**
 * Validate recaptcha token
 * @param {string} token
 * @return {Promise<{success: Boolean, score:Number, challenge_ts: String, hostname: String, action: String}>}
 */
export const validateRecaptchaToken = (token) => {
  return callApi({ method: "POST", body: { token }, endpoint: basePath });
};
