export const fileTypeMixin = {
  data() {
    return {
      imageExtensions: [
        "apng",
        "bmp",
        "gif",
        "ico",
        "cur",
        "jpg",
        "jpeg",
        "jfif",
        "pjpeg",
        "pjp",
        "png",
        "svg",
        "tif",
        "tiff",
        "webp",
      ],
      videoExtensions: [
        "3gp",
        "mpg",
        "mpeg",
        "mp4",
        "m4v",
        "m4p",
        "ogv",
        "ogg",
        "mov",
        "webm",
      ],
      audioExtensions: [
        "3gp",
        "aac",
        "flac",
        "mpg",
        "mpeg",
        "mp3",
        "mp4",
        "m4a",
        "oga",
        "ogg",
        "wav",
        "webm",
      ],
    };
  },
  methods: {
    /**
     * Return file type string
     * @param {string} fileName
     * @returns {string}
     */
    getFileTypeGroup(fileName) {
      const fileExt = fileName.split(".").pop();

      if (this.imageExtensions.indexOf(fileExt) > -1) {
        return "image";
      }
      if (this.videoExtensions.indexOf(fileExt) > -1) {
        return "video";
      }
      if (this.audioExtensions.indexOf(fileExt) > -1) {
        return "audio";
      }
    },
  },
};
