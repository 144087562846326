<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="setPasswordDone" class="mt-4" cols="10" lg="5" md="6">
        <v-alert type="info">
          <p>
            Your password has been changed successfully. Please login using your
            new password.
          </p>
          <router-link class="white--text" title="Login" to="/login"
          >Login here</router-link
          >
        </v-alert>
      </v-col>
      <v-col v-else cols="10" lg="5" md="6">
        <ValidationObserver ref="observer" @submit.prevent="handleSetNewPassword">
          <v-form ref="form">
            <v-card outlined>
              <v-card-text>
                <div class="text-center headline mb-5 font-weight-bold">
                  Set New Password
                </div>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Password"
                  rules="required|min:8"
                >
                  <v-text-field
                    ref="password"
                    v-model="newPassword"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    hint="Min. 8 characters"
                    label="Password"
                    name="password"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-onepassword"
                    required
                    type="password"
                  />
                </Validator>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="ConfirmPassword"
                  rules="required|min:8|password:@Password"
                >
                  <v-text-field
                    v-model="confirmPassword"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    hint="Must be equal to password"
                    label="Confirm Password"
                    name="confirmPassword"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-onepassword"
                    required
                    type="password"
                  />
                </Validator>
              </v-card-text>
              <v-card-actions>
                <v-layout>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        :loading="loading"
                        block
                        color="black"
                        dark
                        depressed
                        type="submit"
                      >
                        Set Password
                      </v-btn>
                    </v-col>
                    <v-col v-if="error">
                      <v-alert dense outlined type="error">
                        Reset password failed. Please try again.
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SetPasswordPage",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      newPassword: "",
      confirmPassword: "",
      setPasswordDone: false,
      error: false,
    };
  },
  methods: {
    async handleSetNewPassword() {
      try {
        this.error = false;
        this.setPasswordDone = false;
        const validForm = await this.$refs.observer.validate();
        if (validForm) {
          this.loading = true;
          const payload = {
            password: this.newPassword,
            token: this.token,
          };
          await this.$store.dispatch("user/setNewPassword", payload);
          this.setPasswordDone = true;
        }
      } catch (err) {
        this.error = true;
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
};
</script>
