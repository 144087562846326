<template>
  <v-dialog v-model="dialog" max-width="320">
    <template v-slot:activator="{ on }">
      <v-btn :disabled="!forPublic" outlined v-on="on"
        >{{ forPublic ? "Set Password" : "Protected" }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"> Set Password</v-card-title>
      <v-card-text>
        <Input-Password @input="passwordChanged" />
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="handleCancel">Cancel</v-btn>
        <v-spacer />
        <v-btn outlined @click="handleSetPassword">Set</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputPassword from "./InputPassword";

export default {
  name: "ButtonSetPassword",
  components: { InputPassword },
  props: {
    mediaId: {
      type: String,
      required: true,
    },
    forPublic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      password: null,
      hint: "",
      showPassword: false,
    };
  },
  methods: {
    handleCancel() {
      this.close();
    },
    passwordChanged({ password, hint }) {
      this.password = password;
      this.hint = hint;
    },
    async handleSetPassword() {
      if (this.password.length < 4) {
        return;
      }

      await this.$store.dispatch("media/setMediaPassword", {
        password: this.password,
        hint: this.hint,
      });
      this.close();
    },
    close() {
      this.password = null;
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
