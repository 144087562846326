import { extend, setInteractionMode, ValidationProvider } from "vee-validate";
import {
  alpha_num,
  confirmed,
  email,
  max,
  min,
  required,
} from "vee-validate/dist/rules";

setInteractionMode("lazy");

// Add a rule.
extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);
extend("alpha_num", alpha_num);

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default ValidationProvider;
