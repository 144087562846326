<template>
  <Icon v-bind="$attrs" :icon="name" :width="width" :height="height" />
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "MyIcon",
  props: {
    name: {
      required: true,
      type: String
    }
  },
  components: {
    Icon,
  },
  data() {
    return {
      width: 28,
      height: 28
    }
  }
};
</script>
