<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="user.id" class="text-center">
        <h1>
          You already logged in. Go
          <router-link :to="{ path: '/' }">here</router-link>
          to upload file.
        </h1>
      </v-col>
      <v-col v-else cols="10" lg="5" md="6">
        <ValidationObserver ref="observer">
          <v-form ref="form" @submit="handleRegister">
            <v-card outlined>
              <v-card-text>
                <div class="text-center headline mb-5 font-weight-bold">
                  Register
                </div>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Username"
                  rules="required|max:16|min:6|alpha_num"
                >
                  <v-text-field
                    v-model="newUser.username"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    hint="Min. 6 chars, max. 16 chars"
                    label="Username"
                    maxlength="16"
                    name="username"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-account-outline"
                    required
                  />
                </Validator>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="newUser.email"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    label="Email"
                    name="email"
                    outlined
                    prepend-inner-icon="mdi-at"
                    required
                    type="email"
                  />
                </Validator>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Password"
                  rules="required|min:8|password:@ConfirmPassword"
                >
                  <v-text-field
                    ref="password"
                    v-model="newUser.password"
                    :error-messages="errors[0]"
                    :validate-on-blur="true"
                    color="black"
                    dense
                    hint="Min. 8 characters"
                    label="Password"
                    name="password"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-onepassword"
                    required
                    type="password"
                  />
                </Validator>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="ConfirmPassword"
                  rules="required|min:8"
                >
                  <v-text-field
                    v-model="newUser.confirmPassword"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    hint="Must be equal to password"
                    label="Confirm Password"
                    name="confirmPassword"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-onepassword"
                    required
                    type="password"
                  />
                </Validator>
                <v-alert v-if="error" dense text type="error">
                  {{ errorMsg }}
                </v-alert>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-layout>
                  <v-flex>
                    <v-btn
                      :loading="loading"
                      block
                      color="black"
                      dark
                      depressed
                      type="submit"
                      @click="handleRegister"
                    >
                      Register
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { R_PROFILE_MEDIA_PAGE } from "@/constants/router";

export default {
  name: "RegisterPage",
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      error: false,
      loading: false,
      newUser: {
        username: null,
        password: null,
        email: null,
        confirmPassword: null,
      },
      errorMsg: "Registration error. Please try again later.",
    };
  },
  methods: {
    async handleRegister(e) {
      e.preventDefault();

      try {
        this.error = false;

        const validForm = await this.$refs.observer.validate();
        if (!validForm) {
          return;
        }

        this.loading = true;
        const { username, password, email } = this.newUser;
        await this.$store.dispatch("user/registerUser", {
          username,
          password,
          email,
        });
        this.$store.dispatch("user/checkLogin");
        this.$router.push({ name: R_PROFILE_MEDIA_PAGE });
      } catch (err) {
        if (err.message) {
          this.errorMsg = err.message;
        }
        this.error = true;
      }

      this.loading = false;
    },
  },
};
</script>
