<template>
  <!-- TODO: this should be a layout, consists of child routes -->
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="5">
        <v-card>
          <v-card-title>
            User Info
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div class="font-weight-bold">Username:</div>
              <div class="mx-3">{{ user.username }}</div>
            </div>
            <div class="d-flex">
              <div class="font-weight-bold">Email:</div>
              <div class="mx-3">{{ user.email }}</div>
            </div>

            <div v-if="user.plan === 'standard' && !user.subscribed">
              No subscription
            </div>

            <div v-if="user.cancelUrl">
              <v-divider class="my-4" />
              <div class="font-weight-bold text-body-1">
                Subscribed for {{user.planName}}
              </div>
              <div class="d-flex justify-space-between font-weight-bold">
                <div>
                  <a :href="user.receiptUrl" target="_blank">Receipt</a>
                </div>
                <div>
                  <a href="#" @click="handleCancel">Cancel subscription</a>
                </div>
              </div>
            </div>
            <v-alert
              dense
              color="black"
              class="my-2"
              type="info"
              v-if="!user.cancelUrl && user.subscriptionCancelledAt"
            >
              Subscription will valid until {{new Date(user.subscriptionCancelledAt).toLocaleDateString()}} and won't
              be auto-renewed.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { profileMixin } from "../mixin/profileMixin";

export default {
  name: "ProfileInfoPage",
  mixins: [profileMixin],
  data() {
    return {};
  },
  created() {
    this.pullInfo();
  },
  methods: {
    handleCancel() {
      window.Paddle.Checkout.open({
        override: this.user.cancelUrl,
        successCallback: this.handleCancelSuccess
      });
    },
    handleCancelSuccess() {
      this.pullInfo();
    }
  }
};
</script>
