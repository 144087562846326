<template>
  <v-btn v-if="user.id" class="grey lighten-3 black--text" icon @click="logout">
    <MyIcon name="mdi:logout" />
  </v-btn>
</template>

<script>
import { logoutMixin } from "../mixin/logoutMixin";
import MyIcon from "./MyIcon";

export default {
  name: "ButtonLogout",
  components: { MyIcon },
  mixins: [logoutMixin],
};
</script>
