<template>
  <v-form ref="form" lazy-validation>
    <v-text-field
      v-model="password"
      :error="invalidPassword"
      :error-messages="errorMessage"
      color="black"
      placeholder="Password"
    />
    <br />
    <v-alert
      v-if="media.hint"
      border="left"
      color="black"
      colored-border
      dense
      elevation="2"
      icon="mdi-information-outline"
      >{{ media.hint }}
    </v-alert>
    <v-btn :loading="loading" block dark depressed @click="validatePassword">
      <MyIcon name="mdi:lock-open" />
      Unlock
    </v-btn>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import MyIcon from "./MyIcon";

export default {
  name: "FormPassword",
  components: { MyIcon },
  computed: mapState({
    media: (state) => state.media,
  }),
  data() {
    return {
      password: "",
      invalidPassword: false,
      errorMessage: "",
      loading: false,
    };
  },
  methods: {
    async validatePassword() {
      this.resetState();
      this.loading = true;
      try {
        const { forPublic, sourceUrl, viewCount } = await this.$store.dispatch(
          "media/decryptMedia",
          this.password
        );
        this.$emit("unlocked", { forPublic, sourceUrl, viewCount });
      } catch (err) {
        if (err.statusCode === 401) {
          this.invalidPassword = true;
          this.errorMessage = "Invalid password";
        } else {
          alert("Error!");
        }
      }
      this.loading = false;
    },
    resetState() {
      this.invalidPassword = false;
      this.errorMessage = "";
    },
  },
};
</script>
