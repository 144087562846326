import { DateTime } from "luxon";
import humanizeDuration from "humanize-duration";

export const formatTimestamp = (timestamp, formatStr = "DD T") => {
  return DateTime.fromSeconds(timestamp).toFormat(formatStr);
};

export const humanizeSecond = (seconds) => {
  return humanizeDuration(seconds, { units: ["d", "h"], round: true });
};
