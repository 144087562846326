<template>
  <v-btn :loading="loading" class="ml-2" outlined @click="handleDelete">
    <MyIcon name="mdi:delete" />
  </v-btn>
</template>

<script>
import MyIcon from "./MyIcon";
export default {
  name: "ButtonDelete",
  components: { MyIcon },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleDelete() {
      const { id, token } = this.media;
      this.loading = true;
      try {
        await this.$store.dispatch("user/deleteMedia", { id, token });
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>
