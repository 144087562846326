import {
  decryptMedia,
  getMedia,
  getMediaSourceUrl,
  setMediaPassword,
  uploadMedia,
} from "../../services/mediaService";

const initialState = {
  id: "",
  token: "",
  mediaType: "",
  name: "",
  shortUrl: "",
  expiryTime: -1,
  viewToken: "",
  sourceUrl: "",
  viewCount: 0,
  expired: false,
  forPublic: true,
  hint: "",
};

const getters = {};

const actions = {
  async setMedia({ commit }, media) {
    commit("setMedia", media);
  },
  async uploadMedia({ commit }, media) {
    const { id, token } = await uploadMedia(media);
    commit("setMedia", { id, token });
    return { id, token };
  },
  async getMedia({ commit }, { id, token }) {
    const media = await getMedia(id, token);
    if (media && media.mediaType) {
      commit("setMedia", media);
      return media;
    }
    return Promise.reject("Media not found!");
  },
  async getMediaSourceUrl({ commit }, { viewToken }) {
    const media = await getMediaSourceUrl(viewToken);
    commit("setMedia", media);
  },
  /* eslint-disable-next-line */
  async setMediaPassword({ commit, state }, { password, hint }) {
    // TODO: hash the password!!
    const media = await setMediaPassword(state.id, {
      mediaToken: state.token,
      password,
      hint,
    });
    commit("setMedia", media);
  },
  async decryptMedia({ commit, state }, password) {
    const { forPublic, sourceUrl, viewCount } = await decryptMedia(
      state.id,
      password
    );
    commit("setMedia", { forPublic, sourceUrl, viewCount });
    return { forPublic, sourceUrl, viewCount };
  },
  resetMedia({ commit }) {
    commit("setMedia", { ...initialState });
  },
};

const mutations = {
  setMedia(state, media) {
    for (const prop in media) {
      if (Object.prototype.hasOwnProperty.call(state, prop)) {
        state[prop] = media[prop];
      }
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
