<template>
  <v-form>
    <v-row>
      <!-- simple stupid way :D -->
      <v-col cols="12" sm="8">
        <InputFile
          :loading="uploading"
          @change="fileChanged" />
      </v-col>
      <v-col cols="12" sm="4">
        <SelectExpiryTime @change="expiryTimeChanged" />
      </v-col>
      <v-col>
        <ButtonUploads
          :media-upload="mediaUpload"
          @uploading="handleUploading"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectExpiryTime from "./SelectExpiryTime";
import ButtonUploads from "./ButtonUploads";
import InputFile from "./InputFile";
import { MediaUpload } from "@/types/mediaUpload";

export default {
  name: "FormUpload",
  components: { SelectExpiryTime, ButtonUploads, InputFile },
  data() {
    return {
      error: false,
      mediaUpload: new MediaUpload(null, 1),
      uploading: false,
    };
  },
  methods: {
    fileChanged(file) {
      this.mediaUpload.media = file;
    },
    expiryTimeChanged(expiryTime) {
      this.mediaUpload.expiryTime = expiryTime;
    },
    handleUploading(uploading) {
      this.uploading = uploading;
    },
  },
};
</script>
