<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="resetDone" class="mt-4" cols="10" lg="5" md="6">
        <v-alert type="info">
          Please check your email and follow the instruction to reset your
          password.
        </v-alert>
      </v-col>
      <v-col v-else cols="10" lg="5" md="6">
        <ValidationObserver ref="observer" @submit.prevent="handleResetPassword">
          <v-form ref="form">
            <v-card outlined>
              <v-card-text>
                <div class="text-center headline mb-5 font-weight-bold">
                  Reset Password
                </div>
                <Validator
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="email"
                    :error-messages="errors[0]"
                    color="black"
                    dense
                    label="Email"
                    maxlength="16"
                    name="email"
                    outlined
                    persistent-hint
                    prepend-inner-icon="mdi-email"
                    required
                  />
                </Validator>
              </v-card-text>

              <v-card-actions>
                <v-layout>
                  <v-flex>
                    <v-btn
                      :loading="loading"
                      block
                      color="black"
                      dark
                      depressed
                      type="submit"
                    >
                      Reset Password
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ResetPasswordPage",
  data() {
    return {
      loading: false,
      email: null,
      resetDone: false,
    };
  },
  methods: {
    async handleResetPassword() {
      try {
        const validForm = await this.$refs.observer.validate();
        if (validForm) {
          this.loading = true;
          await this.$store.dispatch("user/resetPassword", this.email);
          this.resetDone = true;
        }
      } catch (err) {
        console.error(err);
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
};
</script>
