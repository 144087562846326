<template>
 <v-container>
   <v-row>
     <v-col class="text-center">
       <h1 class="mb-4">
         Page Not Found!
       </h1>
       <v-btn href="/" large>Back to main page</v-btn>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
