<template>
  <v-select
    v-model="selectedExpiryTime"
    :items="expiryTimes"
    color="black"
    dense
    hide-details
    outlined
    placeholder="Expiry Time"
    @change="handleChange"
    label="Expiry times"
  >
    <template #prepend-inner>
      <MyIcon name="mdi:timer" />
    </template>
  </v-select>
</template>

<script>
import { mapState } from "vuex";
import MyIcon from "./MyIcon";

export default {
  name: "SelectExpiryTime",
  components: { MyIcon },
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      guestExpiryTimes: [
        { text: "1 Day", value: 1 },
        { text: "3 Days", value: 3 },
      ],
      standardExpiryTimes: [
        { text: "1 Day", value: 1 },
        { text: "2 Days", value: 2 },
        { text: "3 Days", value: 3 },
        { text: "5 Days", value: 3 },
        { text: "1 week", value: 7 },
      ],
      paidExpiryTimes: [
        { text: "1 Day", value: 1 },
        { text: "3 Days", value: 3 },
        { text: "5 Days", value: 5 },
        { text: "10 Days", value: 3 },
        { text: "2 Weeks", value: 14 },
        { text: "3 Weeks", value: 21 },
        { text: "30 Days", value: 30 },
      ],
      expiryTimes: [],
      selectedExpiryTime: null,
    };
  },
  mounted() {
    this.selectedExpiryTime = this.guestExpiryTimes[0];
    if (this.user.id) {
      if (this.user.plan === 'standard') {
        this.expiryTimes = this.standardExpiryTimes
      } else if (this.user.plan === 'paid') {
        this.expiryTimes = this.paidExpiryTimes
      }
    } else {
      this.expiryTimes = this.guestExpiryTimes
    }

    this.$emit("change", this.selectedExpiryTime.value); // set default
  },
  methods: {
    handleChange() {
      this.$emit("change", this.selectedExpiryTime);
    },
  },
};
</script>
