<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col>
        <section class="section-1">
          <v-row justify="center" class="px-4">
            <v-col cols="12" lg="6" md="7" sm="10">
              <div class="text-center">
                <h1 class="font-weight-bold display-1">
                  Upload, Share, and Forget
                </h1>
              </div>
              <div class="mt-6"></div>
              <v-card flat outlined>
                <v-card-text>
                  <FormUpload />
                </v-card-text>
              </v-card>

              <aside class="font-weight-bold my-3 text-center">
                Do you need to convert your PDF file before uploading it? Check our tool <a
                href="https://www.bigpdfconverter.com/" title="Big PDF Converter">PDF converter tool</a>
              </aside>
            </v-col>
          </v-row>
        </section>
        <section class="section-2">
          <v-row style="height: 100%" class="px-4">
            <v-col cols="12">
              <h2 class="text-center my-6">User Comparison</h2>
              <v-row justify="center">
                <v-col cols="12" sm="4" md="3"
                       :key="comparison.title"
                       v-for="comparison of featureComparison">
                  <v-card outlined dark style="height: 100%">
                    <v-card-text>
                      <div
                        class="text-center text-h5 font-weight-bold text--white my-4">
                        {{ comparison.title }}
                      </div>
                      <v-list dense>
                        <v-list-item
                          :key="feature"
                          v-for="feature of comparison.features"
                        >
                          <v-list-item-content class="font-weight-bold">
                            {{ feature }}
                          </v-list-item-content>
                        </v-list-item>
                        <div v-if="comparison.id === 3" class="d-flex justify-center">
                          <v-alert
                            v-if="!user.id"
                            color="success"
                            class="text-center"
                            @click="goToLogin"
                          >
                            Subscribe here
                          </v-alert>
                          <ButtonPlan v-if="!user.subscribed" />
                        </div>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormUpload from "../components/FormUpload.vue";
import { mapState } from "vuex";
import ButtonPlan from "../components/ButtonPlan";

export default {
  name: "HomePage",
  components: { ButtonPlan, FormUpload },
  computed: mapState({
    user: state => state.user
  }),
  data() {
    return {
      featureComparison: [
        {
          id: 1,
          title: "Guest",
          features: [
            "FREE!",
            "Expiry time option 1 day and 3 days",
            "Max file size 10MB"
          ]
        },
        {
          id: 2,
          title: "Registered",
          features: [
            "FREE!",
            "More expiry time options, up to 1 week",
            "30 files / month",
            "File management",
            "Max file size 30MB"
          ]
        },
        {
          id: 3,
          title: "Paid",
          features: [
            "$4 monthly or $35 yearly",
            "Custom expiry time options, up to 30 days",
            "Unlimited files",
            "File management",
            "Max file size 50MB"
          ]
        }
      ]
    };
  },
  methods: {
    goToLogin() {
      this.$router.push('/login')
    }
  }
};
</script>

<style lang="scss">
.section-1 {
  padding: 150px 0;
}

.section-2 {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding-bottom: 60px;
}
</style>
