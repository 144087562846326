<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="7">
        <h1 class="text-center">FAQ</h1>
        <main>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item,i) in items"
              :key="i"
            >
              <v-expansion-panel-header>
                <div  class="font-weight-bold">{{ item.header }}</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="item.content"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </main>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FAQPage",
  data() {
    return {
      items: [
        {
          header: "What happen when the uploaded file expired?",
          content:
            "The file will be deleted from our storage when it reaches the expiration time. We do not store expired file in our server."
        },
        {
          header: "What if I forget the link?",
          content:
            "The link only created once and it is only available after the file uploaded. If you upload the file as a registered user, you can get the link from the media management page. If you uploaded the file as a guest, you need to re-upload the file again."
        },
        {
          header: 'I forget the password of the file. How to reset it?',
          content:
            'Unfortunately, there is no way to reset the password of the file'
        },
        {
          header: 'Which file I can upload?',
          content:
            'Basically you can upload any file up to 50 MB (depends on your user plan). Supported images and videos format will be displayed in the view page. If it is not supported, there will be a download button you can use to download the file into your computer. ' +
            '<p class="font-weight-bold">Please be careful to download file from people you do not know. You should scan the file with antivirus after downloading it.</p>'
        },
        {
          header: "I have another question",
          content: "Email us at <a href='mailto:dev@crossapp.dev'>dev@crossapp.dev</a>"
        }
      ]
    };
  }
};
</script>

<style scoped>

</style>
