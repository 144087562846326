<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="user.id" class="text-center">
        <h1>
          You already logged in. Go
          <router-link :to="{ path: '/' }">here</router-link>
          to upload file.
        </h1>
      </v-col>

      <v-col v-else cols="10" lg="4" md="6" sm="7">
        <v-form ref="form" @submit="handleLogin">
          <v-card outlined>
            <v-card-text>
              <div class="text-center headline mb-5 font-weight-bold">Login</div>
              <v-text-field
                v-model="form.username"
                color="black"
                dense
                label="Username"
                name="username"
                outlined
                required
              />
              <v-text-field
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                autocomplete
                color="black"
                dense
                label="Password"
                name="password"
                outlined
                required
                @click:append="showPassword = !showPassword"
              />
              <v-alert v-if="error" dense text type="error">
                Invalid username / password
              </v-alert>
              <div class="d-flex justify-space-between">
                <div>
                  <router-link :to="{ name: R_REGISTER_PAGE }"
                  >Register here
                  </router-link>
                </div>
                <router-link :to="{ name: R_RESET_PASSWORD_PAGE }"
                >Reset password
                </router-link>
              </div>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                :loading="loading"
                block
                color="black"
                dark
                depressed
                type="submit"
                @click="handleLogin"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  R_PROFILE_MEDIA_PAGE,
  R_REGISTER_PAGE,
  R_RESET_PASSWORD_PAGE,
} from "@/constants/router";

export default {
  name: "LoginPage",
  computed: mapState({
    user: (state) => state.user,
  }),
  data() {
    return {
      showPassword: false,
      form: {
        username: null,
        password: null,
      },
      error: false,
      loading: false,
      R_RESET_PASSWORD_PAGE,
      R_REGISTER_PAGE,
    };
  },
  methods: {
    // TODO: please change all button handler method name
    async handleLogin(e) {
      e.preventDefault();

      if (!this.form.username || !this.form.password) {
        return;
      }

      this.error = false;
      this.loading = true;
      try {
        await this.$store.dispatch("user/login", { ...this.form });
        this.form = {
          username: null,
          password: null,
        };
        this.$router.push({ name: R_PROFILE_MEDIA_PAGE });
      } catch (err) {
        this.error = true;
      }

      this.loading = false;
    },
  },
};
</script>
