import Vue from "vue";
import Router from "vue-router";
import HomePage from "./pages/HomePage.vue";
import MediaPage from "./pages/MediaPage.vue";
import {
  R_ABOUT_PAGE,
  R_HOME_PAGE,
  R_LOGIN_PAGE,
  R_MEDIA_PAGE,
  R_PROFILE_INFO_PAGE,
  R_PROFILE_LAYOUT,
  R_PROFILE_MEDIA_PAGE,
  R_PROFILE_VIEW_MEDIA_PAGE,
  R_REGISTER_PAGE,
  R_RESET_PASSWORD_PAGE,
  R_SET_PASSWORD_PAGE,
  R_VIEW_PAGE,
  R_FAQ_PAGE, R_PRIVACY_POLICY, R_TERM_OF_USE,
} from "./constants/router";
import ViewPage from "./pages/ViewPage";
import ProfileLayout from "./layout/ProfileLayout";
import ProfileInfoPage from "./pages/ProfileInfoPage";
import ProfileMediaPage from "./pages/ProfileMediaPage";
import PageNotFound from "./pages/PageNotFound";
import AboutPage from "./pages/AboutPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ProfileViewMedia from "./pages/ProfileViewMedia";
import SetPasswordPage from "@/pages/SetPasswordPage";
import FAQPage from "./pages/FAQPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermOfUse from "./components/TermOfUse";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: R_HOME_PAGE,
      component: HomePage,
      meta: { mainRoute: true, title: "Home" },
    },
    {
      path: "/media",
      name: R_MEDIA_PAGE,
      props: true,
      component: MediaPage,
    },
    {
      path: "/view",
      name: R_VIEW_PAGE,
      props: true,
      component: ViewPage,
    },
    {
      path: "/profile",
      name: R_PROFILE_LAYOUT,
      component: ProfileLayout,
      redirect: "/profile/info",
      meta: { profileRoute: true },
      children: [
        {
          path: "/profile/info",
          name: R_PROFILE_INFO_PAGE,
          component: ProfileInfoPage,
          meta: {
            title: "Info",
            link: "/profile/info",
          },
        },
        {
          path: "/profile/medias",
          name: R_PROFILE_MEDIA_PAGE,
          component: ProfileMediaPage,
          meta: {
            title: "Medias",
            link: "/profile/medias",
          },
        },
        {
          path: "/profile/medias/:id",
          name: R_PROFILE_VIEW_MEDIA_PAGE,
          component: ProfileViewMedia,
          props: true,
          meta: {
            title: "View",
            hidden: true,
          },
        },
      ],
    },
    {
      path: "/login",
      name: R_LOGIN_PAGE,
      component: LoginPage,
      meta: { mainRoute: true, title: "Login" },
    },
    {
      path: "/resetPassword",
      name: R_RESET_PASSWORD_PAGE,
      component: ResetPasswordPage,
      meta: { mainRoute: false, title: "Reset Password" },
    },
    {
      path: "/register",
      name: R_REGISTER_PAGE,
      component: RegisterPage,
      meta: { mainRoute: false, title: "Register" },
    },
    {
      path: "/about",
      name: R_ABOUT_PAGE,
      component: AboutPage,
      meta: { mainRoute: true, title: "About" },
    },
    {
      path: "/faq",
      name: R_FAQ_PAGE,
      component: FAQPage,
      meta: { mainRoute: false, title: "Faq" },
    },
    {
      path: "/privacy-policy",
      name: R_PRIVACY_POLICY,
      component: PrivacyPolicy,
      meta: { mainRoute: false, title: "Privacy&Policy" },
    },
    {
      path: "/term-of-use",
      name: R_TERM_OF_USE,
      component: TermOfUse,
      meta: { mainRoute: false, title: "Term of Use" },
    },
    {
      path: "/setPassword/:token",
      name: R_SET_PASSWORD_PAGE,
      component: SetPasswordPage,
      props: true,
      meta: { title: "Set New Password" },
    },
    { path: "*", component: PageNotFound },
  ],
  scrollBehavior() {
    // for auto scroll to top on route change
    const app = document.getElementById("app");
    if (app) {
      app.scrollIntoView({ behavior: 'smooth' });
    }
  }
});
